interface BonusParams {
  id: string | number
  type:
    | 'deposit'
    | 'cash'
    | 'freespin'
    | 'freebet'
    | 'cashback'
    | 'rakeBack'
    | 'reload'
    | 'ratRace'
    | 'vip'
}

export function useBonusTooltip(bonusParams: Ref<BonusParams> | BonusParams) {
  const { t } = useI18n()
  const router = useRouter()
  const tooltipName = computed(
    () =>
      ({
        deposit: 'depositBonusTooltip',
        cash: 'cashBonusTooltip',
        freespin: 'freespinTooltip',
        freebet: 'freebetTooltip',
        cashback: 'regularBonusTooltip',
        rakeBack: 'regularBonusTooltip',
        reload: 'regularBonusTooltip',
        ratRace: '',
        vip: '',
      })[unref(bonusParams).type],
  )
  const tooltipTitle = computed(
    () =>
      ({
        deposit: t('bonuses.aboutDeposit'),
        cash: t('bonuses.aboutDeposit'),
        freespin: t('bonuses.aboutFreespins'),
        freebet: t('bonuses.aboutFreebet'),
        cashback: t('bonuses.aboutCashback'),
        rakeBack: t('bonuses.aboutRakeBack'),
        reload: t('bonuses.aboutReload'),
        ratRace: '',
        vip: '',
      })[unref(bonusParams).type] ?? '',
  )
  function openTooltip() {
    if (!tooltipName.value) return

    router.replace({
      query: {
        modal: tooltipName.value,
        id: unref(bonusParams).id,
      },
    })
  }

  return {
    openTooltip,
    tooltipName,
    tooltipTitle,
  }
}
