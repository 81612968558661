<template>
  <div class="bonus-timer" :class="direction" data-t="bonus-timer">
    <span v-if="isExpired" class="timer-title">
      {{ t('bonuses.timeExpired') }}
    </span>
    <template v-else>
      <span v-if="label" class="timer-title" data-t="timer-tilte">
        {{ label }}
      </span>
      <span class="timer-countdown" data-t="timer-countdown">
        {{ countdown }}
      </span>
    </template>
  </div>
</template>

<script setup lang="ts">
interface Props {
  expiresAt: string
  label?: string
  direction?: 'row' | 'column'
}
const { expiresAt, label, direction = 'column' } = defineProps<Props>()

const { t } = useI18n()

const { countdown, isExpired } = useCountdownV2(computed(() => expiresAt ?? ''))
</script>

<style scoped>
.timer-title {
  font: var(--mobile-caption-1-regular);
  color: var(--text-secondary);
}

.timer-countdown {
  font: var(--mobile-caption-1-regular);
  color: var(--text-primary);
}

.bonus-timer {
  display: flex;
  flex: 1 0 0;
  flex-direction: column;
  gap: var(--spacing-025);
  align-items: center;
  justify-content: center;

  min-width: 0;
  min-height: 40px;
  /* stylelint-disable-next-line */
  padding: 5px var(--spacing-125);

  &.row {
    flex-direction: row;
    gap: var(--spacing-050);
    justify-content: flex-end;

    min-height: auto;
    padding: 0;

    .timer-countdown {
      text-align: right;
    }
  }
}
</style>
